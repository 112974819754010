import { Row, Col, Tab, Nav } from "react-bootstrap";

import netCoreLogo from "../../assets/img/netcorelogo.png";
import azureCloudLogo from "../../assets/img/azurecloudlogo.png";
import cosmosLogo from "../../assets/img/cosmoslogo.png";
import postgresLogo from "../../assets/img/postgreslogo.png";
import reactLogo from "../../assets/img/reactlogo.png";
import blazorLogo from "../../assets/img/blazorlogo.png";
import androidLogo from "../../assets/img/androidlogo.png";
import mauiLogo from "../../assets/img/mauilogo.png";
import kotlinLogo from "../../assets/img/kotlinlogo.png";
import awsLogo from "../../assets/img/awslogo.png";
import threejsLogo from "../../assets/img/threejslogo.png";
import nextjsLogo from "../../assets/img/nextjsLogo.png";
import firebaseLogo from "../../assets/img/firebaseLogo.png";
import Carousel from "react-multi-carousel";

import "./Technologies.css"

import "react-multi-carousel/lib/styles.css";
import React from "react";

const Technologies: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="technologies" id="technologies">
    <div className="section-container">
        <Row>
          <Col size={12}>
            <h2>Technologies</h2>
                  <Tab.Container id="technologies-tabs" defaultActiveKey="first" mountOnEnter={true} unmountOnExit={true} >
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Backend</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Frontend</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content

                    >
                      <Tab.Pane eventKey="first">            
                        <Carousel
                          responsive={responsive}
                          infinite={true}
                          className="technologies-slider"
                        >
                          <div className="item">
                            <img src={netCoreLogo} alt=".NET Core" />
                            <h5>.NET</h5>
                          </div>
                          <div className="item">
                            <img src={postgresLogo} alt="PostgreSQL" />
                            <h5>PostgreSQL</h5>
                          </div>
                          <div className="item">
                            <img src={azureCloudLogo} alt="Azure Cloud" />
                            <h5>Azure Cloud</h5>
                          </div>
                          <div className="item">
                          <img src={firebaseLogo} alt="Firebase" />
                          <h5>Firebase</h5>
                        </div>
                          <div className="item">
                            <img src={cosmosLogo} alt="Cosmos" />
                            <h5>Azure Cosmos</h5>
                          </div>    
                          <div className="item">
                            <img src={awsLogo} alt="AWS" />
                            <h5>AWS</h5>
                          </div>
                        </Carousel>
                        <p>
                          I most commonly find myself implementing my backend applications in newest .NET, 
                          preferably leaning towards a serverless architecture with either AWS or
                          Azure as a cloud provider - In which I'm <a
                              href="https://www.credly.com/badges/b41b82d6-69cd-4a52-b093-fab36aa9c32f"
                              target="_blank"
                              rel="noreferrer">
                            certified
                          </a>!
                          Despite loving to develop backend solutions, I've also learned to recognize the
                          powerful nature of Firebase, PocketBase and other backend-as-a-service solutions for smaller
                          or starting projects. I'm familiar with both relational and schemaless databases, with the
                          character of the application being the deciding factor between the two.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                      <Carousel
                          responsive={responsive}
                          infinite={true}
                          className="technologies-slider"          
                        >
                          <div className="item">
                            <img src={blazorLogo} alt="Blazor" />
                            <h5>.NET Blazor</h5>
                          </div>
                          <div className="item">
                            <img src={reactLogo} alt="React" />
                            <h5>React</h5>
                            </div>
                        <div className="item">
                          <img src={androidLogo} alt="Android" />
                          <h5>Android Native</h5>
                        </div>
                        <div className="item">
                        <img src={nextjsLogo} alt="NextJS" />
                        <h5>Next.JS</h5>
                      </div>
                        <div className="item">
                            <img src={threejsLogo} alt="ThreeJS" />
                            <h5>Three.JS</h5>
                          </div>         
                          <div className="item">
                            <img src={kotlinLogo} alt="Kotlin" />
                            <h5>Kotlin Multiplatform</h5>
                          </div>
                          <div className="item">
                            <img src={mauiLogo} alt="MAUI" />
                            <h5>.NET MAUI</h5>
                          </div>
                        </Carousel>
                        <p>
                          Although I generally don't enjoy frontend development as much as backend,
                          my experience has made me proficient in developing web applications in either Blazor or
                          a variety of Typescript frameworks, most commonly React and Next.js.
                          I'm also versed in development of Android applications in Kotlin,
                          or multiplatform applications in React Native, MAUI and Kotlin Multiplatform.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Technologies;