import { Col } from "react-bootstrap";

type Props = {
  title: string
  description: string
  imgUrl: string;
  url: string;
};

export const ProjectCard = ({ title, description, imgUrl, url }: Props) => {
  return (
    <Col size={6} sm={6} md={6} lg={6} xl={6} xxl={2}>
      <a href={url} target="_blank" rel="noreferrer">
      <div className="proj-imgbx">
        <img src={imgUrl} className='proj-imgbx-img' alt="Project"/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      </a>
    </Col>
  )
}
