import './App.css';
import { About } from './components/about/About';
import Banner from './components/banner/Banner';
import Career from './components/career/Career';
import Footer from './components/footer/Footer';
import  NavBar from './components/navbar/NavBar';
import './components/navbar/NavBar.css';
import Projects from './components/projects/Projects';
import Technologies from './components/technologies/Technologies';

function App() {
  return (
    <div className="App">
        <NavBar/>
        <Banner/>
        <About/>
        <Technologies/>
        <Career/>
        <Projects/>
        <Footer/>
    </div>
  );
}

export default App;
