import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { Container, Row, Col } from "react-bootstrap";
import 'react-vertical-timeline-component/style.min.css';
import tietoevryLogo from "../../assets/img/tietoevrylogo.png";
import workLogo from "../../assets/img/worklogo.png";
import React from 'react';

import "./Career.css"

const Career: React.FC = () => {
      return (<section className="career" id="career">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Career</h2>
<VerticalTimeline>

<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="May 2019 - Nov 2019"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">GINA Software</h3>
    <h5 className="vertical-timeline-element-title">Software tester, Part-time</h5>
    <p>
      I started my career after the first semester of studying bioinformatics at <a href="https://www.muni.cz/en" target="_blank" rel="noreferrer">MUNI</a>. 
      I landed my first part-time job at <a href="https://www.ginasystem.com/" target="_blank" rel="noreferrer">GINA</a> - a friendly company developing navigational software for critical workers.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="Jul 2019 - Nov 2020"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">CzechGlobe</h3>
    <h5 className="vertical-timeline-element-title">Software developer, Part-time</h5>
    <p>
      2 months after I got my first job, I was met with the opportunity to develop software at <a href="https://sybila.fi.muni.cz/" target="_blank" rel="noreferrer">SYBILA</a>,
      where I would focus on automatizing and optimising bio-chemical research.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="Dec 2019 - Sep 2020"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">GINA Software</h3>
    <h5 className="vertical-timeline-element-title">Android developer, Part-time</h5>
    <p>
      Leveraging the experience gained at the <a href="https://sybila.fi.muni.cz/" target="_blank" rel="noreferrer">SYBILA</a> lab, 
      I've negotiated a tranfer to a development team at <a href="https://www.ginasystem.com/" target="_blank" rel="noreferrer">GINA</a>, 
      where I would work in a newly formed team developing a new Android application in Kotlin.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="Oct 2020 - Aug 2021"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">GINA Software</h3>
    <h5 className="vertical-timeline-element-title">Android developer, Full-time</h5>
    <p>
      After the project at <a href="https://sybila.fi.muni.cz/" target="_blank" rel="noreferrer">SYBILA</a> had concluded, I've taken a fulltime position at <a href="https://www.ginasystem.com/" target="_blank" rel="noreferrer">GINA</a>, 
      where I would continue to work in the mobile team.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="Aug 2021 - Feb 2022"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">GINA Software</h3>
    <h5 className="vertical-timeline-element-title">Software developer, Full-time</h5>
    <p>
      I've taken an interest in backend development, which resulted in me changing my position at <a href="https://www.ginasystem.com/" target="_blank" rel="noreferrer">GINA</a> to a role where I would be allowed to 
      contribute to both our API written in .NET Framework and our mobile applications.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
      contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
      date="Feb 2022 - present"
      iconStyle={{ background: 'black' }}
      icon={<img src={tietoevryLogo} alt="Logo" />}
  >
    <h3 className="vertical-timeline-element-title">Tietoevry</h3>
    <h5 className="vertical-timeline-element-title">Software developer, Full-time</h5>
    <p>
      I continued to work on my skills in backend development, which I've found to enjoy the most.
      My job at <a href="https://www.tietoevry.com/" target="_blank" rel="noreferrer">Tietoevry</a> has allowed me to work with the newest technologies
      and gain knowledge in Azure Cloud.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(255 255 255 / 10%)'}}
    contentArrowStyle={{ borderRight: '7px solid rgb(255 255 255 / 10%)'}}
    date="Feb 2022 - present"
    iconStyle={{ background: 'black' }}
    icon={<img src={workLogo} alt="Logo" />}
>
  <h3 className="vertical-timeline-element-title">Self-employed</h3>
  <h5 className="vertical-timeline-element-title">Software engineer</h5>
  <p>
    Ready to work on my personal projects as well as apply my gained expertise in contract work for various costumers,
    I've moved on to work with <a href="https://www.develogi.cz/" target="_blank" rel="noreferrer">Develogi</a>, where I would act as a software developer,
    consultant and architect.
  </p>
</VerticalTimelineElement>
</VerticalTimeline>
</Col>
        </Row>
      </Container>
    </section>
  );
};

export default Career;