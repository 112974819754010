import { Row, Col } from "react-bootstrap";
import aboutMeImg from "../../assets/img/about.png"

import "./About.css";
import React from "react";

export const About: React.FC = () => {

    return (
        <section className="about" id="about">
            <div className="section-container">
                <Row>
                    <h2>About Me</h2>
                    <Col xs={12} md={12} xl={12} xxl={7} className="align-self-center">
                        <p>Hi! I'm Daniel, a 24 years old, enthusiastic software developer from Slovakia, currently located in Brno, Czech Republic.
                            <br></br><br></br>
                            I fluently speak English, Czech, Slovak, C#, Kotlin, TypeScript and Python, and I can even understand some German and JavaScript (but I won't like doing it).
                            <br></br><br></br>
                            When I'm not coding, I won't turn down a steep hike, a kayaking trip, a bouldering event, a board game evening or a simple beer drinking occasion with friends.
                        </p>
                    </Col>
                    <Col xs={12} md={12} xl={12} xxl={5} className="d-flex justify-content-center">

                        <img
                        src={aboutMeImg}
                        alt="About"
                        // onMouseOver={MouseOverHandler}
                        // onMouseOut={MouseOutHandler}
                        ></img>
                    </Col>

                </Row>
            </div>
        </section>
    );
};
