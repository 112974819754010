import { Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import kotlinlogo from "../../assets/img/kotlinlogo.png";
import pythonlogo from "../../assets/img/pythonlogo.png";
import csharplogo from "../../assets/img/csharplogo.png";
import reactlogo from "../../assets/img/reactlogo.png";
import 'animate.css';
import './Projects.css';
import React from "react";

const Projects: React.FC = () => {

  const projects = [
    {
      title: "Where have I been?",
      description: "React & ThreeJS",
      imgUrl: reactlogo,
      url: "https://travels.dandayne.link"
    },
    {
      title: "BauID",
      description: ".NET 6, React and Xamarin applications",
      imgUrl: csharplogo,
      url: "https://bauid.at/"
    },
    {
      title: "GINA Tablet 2",
      description: "Android application",
      imgUrl: kotlinlogo,
      url: "https://www.ginasystem.com/gina-tablet.php"
    },    
    {
      title: "Device Control",
      description: "Python application",
      imgUrl: pythonlogo,
      url: "https://github.com/SmartBioTech/DeviceControl"
    },
  ];

  return (
    <section className="project" id="projects">
      <div className="section-container">
        <Row>
          <Col size={12}>
                <h2>Projects</h2>
                      <Row className="d-flex justify-content-center"> 
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Projects;
