import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import linkedinLogo from '../../assets/img/linkedinLogo.svg';

import {
  BrowserRouter as Router
} from "react-router-dom";

const NavBar: React.FC = ()=> {

  const [activeLink, setActiveLink] = useState<string | null>('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, )

  const onUpdateActiveLink = (value: string) => {
    setActiveLink(value);
  }

  return (
      <Router>
        <Navbar expand="xl" className={scrolled ? "scrolled" : ""}>
          <div className="navbar-container">
            <Navbar.Brand href="/">
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About</Nav.Link>
                <Nav.Link href="#technologies" className={activeLink === 'technologies' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('technologies')}>Technologies</Nav.Link>
                <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
              </Nav>
              <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/daniel-mago%C4%8D-157430179/" target="_blank" rel="noreferrer"><img src={linkedinLogo} alt="" /></a>
              </div>
            </span>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </Router>
  )
}

export default NavBar;
